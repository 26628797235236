import { Table, Image, Popconfirm, Button, Tag, Spin } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  useAppointmentByUserQuery,
  useAppointmentUpdateMutation,
} from "./service/appointment-api";
import { IMAGE_S3_URL } from "../../../utils/api-config";
import { handleApiError } from "../../../utils/errorHandler";
import { useMailApppointmentMutation } from "../service/mail-api";
import { useContext, useEffect, useState } from "react";
import "./styles/style.scss";
import ChatBox from "../../../components/chatBox/ChatBox";
import { UserContext } from "../../../context/UserContext";

function AppointmentPage() {
  const { user } = useSelector((state) => state.auth);
  const [showChat, setShowChat] = useState(false);
  const [updateAppointment, { isLoading: isUpdating }] = useAppointmentUpdateMutation();
  const [mailApppointment] = useMailApppointmentMutation();
  const [activeConversation, setActiveConversation] = useState(null);
  const [updatingAppointmentId, setUpdatingAppointmentId] = useState(null);
  const { data, isSuccess, isError, error, isFetching, refetch: refetchApps } =
    useAppointmentByUserQuery(
      user.role === "professional"
        ? `professional=${user.id}`
        : `customer=${user.id}`
    );

  const chatClient = useContext(UserContext);

  const handleUpdate = (values) => {
    setUpdatingAppointmentId(values.id); // Set the updating appointment ID
    const customerData = {
      date: `${moment(values.date).format("llll")}`,
      customer: `${user.firstname} ${user.lastname}`,
      professional: `${values.firstname} ${values.lastname}`,
      type: "cancel",
    };
    const professionalData = {
      date: `${moment(values.date).format("llll")}`,
      professional: `${user.firstname} ${user.lastname}`,
      customer: `${values.firstname} ${values.lastname}`,
      type: "cancel",
    };

    const mailData =
      user.role === "professional" ? professionalData : customerData;

    updateAppointment({ id: values.id, data: { status: "Annuler" } })
      .unwrap()
      .then(() => {
        if (mailData) {
          mailApppointment(mailData)
            .unwrap()
            .then()
            .catch((error) => console.log("Mail error: ===>", error));
        }
        setUpdatingAppointmentId(null); // Reset the updating appointment ID
        refetchApps(); // Refetch the appointments to get the latest data
      })
      .catch((error) => {
        console.log("Update error: ===>", error);
        setUpdatingAppointmentId(null); // Reset the updating appointment ID on error
      });
  };

  useEffect(() => {
    refetchApps();
    // eslint-disable-next-line
  }, [data]);

  // Chat creation Function
  const handleClick = async (record) => {
    if (!record.chatId) {
      const conversation = await chatClient.createConversation({
        attributes: {
          user1: {
            id: user.id,
            name: `${user.firstname} ${user.lastname}`,
            email: user.email,
          },
          user2: {
            id: record.userId,
            name: `${record.firstname} ${record.lastname}`,
            email: record.userEmail,
          },
        },
        friendlyName: "Appointment-Chat",
        uniqueName: `${record.libelle} ${moment(record.date).format(
          "hh:mm:ss a"
        )}`,
      });
      await Promise.all([
        conversation.join(),
        conversation.add(`${record.userEmail}`),
        updateAppointment({ id: record.id, data: { chatId: conversation.sid } }),
      ]);
      setActiveConversation(conversation);
      setShowChat(true);
    } else {
      const conversation = await chatClient.getConversationBySid(
        `${record.chatId}`
      );
      setActiveConversation(conversation);
      setShowChat(true);
    }
  };

  const columns = [
    {
      title: "Rendez-vous",
      key: "image",
      render: (record) => (
        <span>
          <Image
            width={60}
            src={`${IMAGE_S3_URL}/${record.image}`}
            preview={false}
          />
          <span style={{ margin: "10px" }}>{record.libelle}</span>
        </span>
      ),
    },
    {
      title: "Prix",
      key: "price",
      render: (record) => <span>{record.price} €</span>,
    },
    {
      title: "Date du rendez-vous",
      key: "date",
      render: (record) => <span>{moment(record.date).format("llll")}</span>,
    },
    {
      title: user.role === "professional" ? "Cliente" : "Coiffeuse",
      key: "role",
      render: (record) => (
        <span>
          {record.firstname} {record.lastname}
        </span>
      ),
    },
    {
      title: "Statut",
      key: "status",
      render: (record) =>
        record.status === "annulé" ? (
          <Tag>Annulé</Tag>
        ) : record.status === "Terminé" ? (
          <Tag>Terminé</Tag>
        ) : (
          <Tag> {record.status}</Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          record.status === "Payé" &&
          !record.isEnded && (
            <Popconfirm
              title="Voulez-vous annuler cette commande ?"
              onConfirm={() => handleUpdate(record)}
              okText="Oui"
              disabled={record.status === "annulé"}
              cancelText="Non"
            >
              <Button
                type="primary"
                className="btn-status"
                loading={isUpdating && updatingAppointmentId === record.id} // Conditionally show loading
              >
                Annuler
              </Button>
            </Popconfirm>
          )
        );
      },
    },
    {
      title: "",
      render: (record) =>
        record.status === "Payé" &&
        !record.isEnded && (
          <button
            className="contact_button"
            onClick={() => handleClick(record)}
          >
            contact
          </button>
        ),
    },
  ];

  return (
    <>
      <div className="appointment animate__animated animate__fadeIn">
        <div className="page-title">
          <span>MES RENDEZ-VOUS</span>
        </div>
        <Spin spinning={isFetching || isUpdating}>
          {isSuccess && data && (
            <Table
              columns={columns}
              dataSource={data}
              loading={isFetching}
              rowKey={(val) => val.id}
            />
          )}
          {isError && handleApiError(error.data.message)}
        </Spin>
      </div>
      {showChat && activeConversation && (
        <ChatBox
          bottom={true}
          activeConversation={activeConversation}
          setShowChat={setShowChat}
          chatClient={chatClient}
        />
      )}
    </>
  );
}

export default AppointmentPage;
