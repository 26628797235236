import "./styles/style.scss";
import { Table } from "antd";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import LastMessage from "../LastMessage/LastMessage";
import { Spin } from "antd";
import { sortItems } from "../../../../utils/conversations";
import { message } from "antd";

const ConversationList = ({ user, setActiveConversation }) => {
  const [search, setSearch] = useState("");
  const [conversations, setConversations] = useState([]),
    [filteredConversations, setFilteredConversations] = useState([]),
    [loading, setLoading] = useState(false),
    chatClient = useContext(UserContext);

  const fetchConversations = async () => {
    if (chatClient) {
      try {
        const { items } = await chatClient?.getSubscribedConversations();
        const sorted = await sortItems(items, user);
        setConversations(sorted);
        setFilteredConversations(sorted);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong trying to get your chats.");
      }
    }
  };

  const getConversations = () => {
    fetchConversations();
    setLoading(true);
  };

  useEffect(() => {
    getConversations();
    chatClient?.on("messageAdded", getConversations);
    // eslint-disable-next-line
  }, [user, chatClient]);

  const handleSearch = () => {

      const filtered = conversations.filter((conversation) =>
        conversation.uniqueName.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredConversations(filtered);
    
  };

  useEffect(() => {
    if (search === "") {
      setFilteredConversations(conversations);
    }
  }, [search, conversations]);

  const columns = [
    {
      title: "Sujet",
      key: "uniqueName",
      render: (record) => (
        <span className={`${record.unreadMessagesCount ? "bold_chat" : ""}`}>
          {record.uniqueName}
        </span>
      ),
    },
    {
      title: "Destinataire",
      key: "attributes",
      render: (record) => {
        let username = "";
        if (record.attributes?.type?.admin) {
          username = "Admin";
        } else {
          username =
            record.attributes?.user1?.id === user?.id
              ? record.attributes.user2.name
              : record.attributes.user1.name;
        }
        return (
          <span className={`${record.unreadMessagesCount ? "bold_chat" : ""}`}>
            {username}
          </span>
        );
      },
    },
    {
      title: "Dernier message",
      key: "lastMessage",
      render: (record) => {
        return <LastMessage conversation={record} />;
      },
    },
    {
      title: "Nombre de messages non lus",
      key: "unreadMessage",
      render: (record) => {
        return (
          <span className={`${record.unreadMessagesCount ? "bold_chat" : ""}`}>
            {record.unreadMessagesCount}
          </span>
        );
      },
    },
    {
      title: "",
      key: "open",
      render: (record) => {
        return (
          <button
            className="chat_open_button"
            onClick={() => {
              setActiveConversation(record);
            }}
          >
            Ouvrir
          </button>
        );
      },
    },
  ];

  return (
    <div className={"conversation-preview__container"}>
      <div className="chat_search_div">
        <input
          type="text"
          placeholder="Rechercher des discussions ici"
          className="search_chat_field"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <button className="search_button" onClick={handleSearch}>
          Recherche
        </button>
      </div>
      {loading ? (
        <div className="loader_div">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {filteredConversations.length ? (
            <Table
              columns={columns}
              dataSource={filteredConversations}
              rowKey={(val) => val.sid}
            />
          ) : (
            <h3>Aucun message pour l'instant</h3>
          )}
        </>
      )}
    </div>
  );
};

export default ConversationList;
