import * as React from "react";
import Banner from "../account/components/banner";

function MentionLegale() {
  return (
    <div className="faq animate__animated animate__fadeIn">
      <Banner title="MENTIONS LÉGALES" />
      <div className="container">
        <div className="content">
          <div className="blc">
            <h5>1. Présentation du site :</h5>
            <p>
              Conformément aux dispositions des articles 6-III et 19 de la Loi
              n° 2004-575 du 21 juin 2004 pour la Confiance dans l&#39;économie
              numérique, dite L.C.E.N., nous portons à la connaissance des
              utilisateurs et visiteurs du site : https://kimekoif.com/ les
              informations suivantes :<br />
              <br />
              Informations légales :<br />
              Statut du propriétaire : Société
              <br />
              Préfixe : SARL
              <br />
              Nom de la Société : STRONG AND BOLD EXTENSIONS
              <br />
              Adresse : 93B RUE SAINT AUBERT 62000 ARRAS
              <br />
              Tél : 0783384178
              <br />
              Au Capital de : 100 €<br />
              SIRET : 85223548000013
              <br />
              Numéro TVA intracommunautaire : FR46852235480
              <br />
              Adresse de courrier électronique : contact@kimekoif.com
              <br />
              <br />
              Le Créateur du site est : Jessy Diandra
              <br />
              Le Responsable de la publication est : Sonia DACKAM
              <br />
              Contactez le responsable de la publication : contact@kimekoif.com
              <br />
              Le responsable de la publication est une personne physique
              <br />
              <br />
              Le webmaster est : Yves Samen
              <br />
              Contactez le webmaster : ysamen@caliber-is.com
              <br />
              L’hebergeur du site est : O2Switch 222 Bd Gustave Flaubert, 63000
              Clermont-Ferrand
              <br />
              <br />
              Hébergeur : Amazon Web Services
              <br/>
              Dénomination Sociale : Amazon CS Ireland Ltd
              <br/>
              Adresse : Unit 27 – 6400 Cork Airport Business Park –
              Kinsale Road – Ireland
              <br/>
              Amazon CS Ireland Ltd est une société du
              groupe Amazon Web Services (AWS), dont le siège social se situe à
              Inc. P.O/ Box 81226 – Seattle, WA 98108-1226, tél : (206) 266-4064 fax: (206) 266-7010
              <br />
              {/* CREDITS : les mentions légales ont été générées par
              http://www.generer-mentions- legales.com
              <br /> */}
            </p>
            <h5>
              2. Conditions générales d’utilisation du site et des services
              proposés
            </h5>
            <p>
              L’utilisation du site https://kimekoif.com/ implique l’acceptation
              pleine et entière des conditions générales d’utilisation décrites
              ci-après. Ces conditions d’utilisation sont susceptibles d’être
              modifiées ou complétées à tout moment, sans préavis, aussi les
              utilisateurs du site https://kimekoif.com/ sont invités à les
              consulter de manière régulière.
              <br />
              https://kimekoif.com/ est par principe accessible aux utilisateurs
              24/24h, 7/7j, sauf interruption, programmée ou non, pour les
              besoins de sa maintenance ou cas de force majeure. En cas
              d’impossibilité d’accès au service, https://kimekoif.com/ s’engage
              à faire son maximum afin de rétablir l’accès au service et
              s’efforcera alors de communiquer préalablement aux utilisateurs
              les dates et heures de l’intervention. N’étant soumis qu’à une
              obligation de moyen, https://kimekoif.com/ ne saurait être tenu
              pour responsable de tout dommage, quelle qu’en soit la nature,
              résultant d’une indisponibilité du service.
              <br />
              <br />
              Le site https://kimekoif.com/ est mis à jour régulièrement par le
              propriétaire du site. De la même façon, les mentions légales
              peuvent être modifiées à tout moment, sans préavis et s’imposent à
              l’utilisateur sans réserve. L’utilisateur est réputé les accepter
              sans réserve et s’y référer régulièrement pour prendre
              connaissance des modifications.
              <br />
              Le site https://kimekoif.com/ se réserve aussi le droit de céder,
              transférer, ce sans préavis les droits et/ou obligations des
              présentes CGU et mentions légales. En continuant à utiliser les
              Services du site https://kimekoif.com/, l’utilisateur reconnaît
              accepter les modifications des conditions générales qui seraient
              intervenues.
              <br />
            </p>
            <h5>3. Description des services fournis :</h5>
            <p>
              Le site https://kimekoif.com/ a pour objet de fournir une
              information concernant l’ensemble des activités de la société.
              <br />
              Le proprietaire du site s’efforce de fournir sur le site
              https://kimekoif.com/ des informations aussi précises que
              possible. Toutefois, il ne pourra être tenue responsable des
              omissions, des inexactitudes et des carences dans la mise à jour,
              qu’elles soient de son fait ou du fait des tiers partenaires qui
              lui fournissent ces informations.
              <br />
              Toutes les informations proposées sur le site
              https://kimekoif.com/ sont données à titre indicatif, sont non
              exhaustives, et sont susceptibles d’évoluer. Elles sont données
              sous réserve de modifications ayant été apportées depuis leur mise
              en ligne.
              <br />
            </p>
            <h5>4. Limites de responsabilité :</h5>
            <p>
              Le site https://kimekoif.com/ utilise la technologie java script.
              <br />
              Le site https://kimekoif.com/ ne saurait être tenu responsable des
              erreurs typographiques ou inexactitudes apparaissant sur le
              service, ou de quelque dommage subi résultant de son utilisation.
              L’utilisateur reste responsable de son équipement et de son
              utilisation, de même il supporte seul les coûts directs ou
              indirects suite à sa connexion à Internet.
              <br />
              L’utilisateur du site https://kimekoif.com/ s’engage à accéder à
              celui-ci en utilisant un matériel récent, ne contenant pas de
              virus et avec un navigateur de dernière génération mise à jour.
              <br />
              L’utilisateur dégage la responsabilité de https://kimekoif.com/
              pour tout préjudice qu’il pourrait subir ou faire subir,
              directement ou indirectement, du fait des services proposés. Seule
              la responsabilité de l’utilisateur est engagée par l’utilisation
              du service proposé et celui-ci dégage expressément le site
              https://kimekoif.com/ de toute responsabilité vis à vis de tiers.
              <br />
              Des espaces interactifs (possibilité de poser des questions dans
              l’espace contact) sont à la disposition des utilisateurs. Le site
              https://kimekoif.com/ se réserve le droit de supprimer, sans mise
              en demeure préalable, tout contenu déposé dans cet espace qui
              contreviendrait à la législation applicable en France, en
              particulier aux dispositions relatives à la protection des
              données. Le cas échéant, le propriétaire du site se réserve
              également la possibilité de mettre en cause la responsabilité
              civile et/ou pénale de l’utilisateur, notamment en cas de message
              à caractère raciste, injurieux, diffamant, ou pornographique, quel
              que soit le support utilisé (texte, photographie…).
              <br />
              Il est ici rappelé que les développeurs du site
              https://kimekoif.com/ gardent trace de l&#39;adresse mail, et de
              l&#39;adresse IP de l&#39;utilisateur. En conséquence, il doit
              être conscient qu&#39;en cas d&#39;injonction de l’autorité
              judiciaire il peut être retrouvé et poursuivi.
            </p>

            <h5>5. Propriété intellectuelle et contrefaçons :</h5>
            <p>
              Le propriétaire du site est propriétaire des droits de propriété
              intellectuelle ou détient les droits d’usage sur tous les éléments
              accessibles sur le site, notamment les textes, images, graphismes,
              logo, icônes, sons, logiciels…
              <br />
              Toute reproduction, représentation, modification, publication,
              adaptation totale ou partielle des éléments du site, quel que soit
              le moyen ou le procédé utilisé, est interdite, sauf autorisation
              écrite préalable à l&#39;email : makymadi@gmail.com.
              <br />
              Toute exploitation non autorisée du site ou de l’un quelconque de
              ces éléments qu’il contient sera considérée comme constitutive
              d’une contrefaçon et poursuivie conformément aux dispositions des
              articles L.335-2 et suivants du Code de Propriété Intellectuelle.
            </p>

            <h5>6. Liens hypertextes et cookies :</h5>
            <p>
              Le site https://kimekoif.com/ contient un certain nombre de liens
              hypertextes vers d’autres sites (partenaires, informations …) mis
              en place avec l’autorisation de le propriétaire du site.
              Cependant, le propriétaire du site n’a pas la possibilité de
              vérifier le contenu des sites ainsi visités et décline donc toute
              responsabilité de ce fait quand aux risques éventuels de contenus
              illicites.
              <br />
              L’utilisateur est informé que lors de ses visites sur le site
              https://kimekoif.com/ Le paramétrage du logiciel de navigation
              permet d’informer de la présence de cookie et éventuellement, de
              refuser de la manière décrite à l’adresse suivante : www.cnil.fr
              Le refus d’installation d’un cookie peut entraîner l’impossibilité
              d’accéder à certains services. L’utilisateur peut toutefois
              configurer son ordinateur de la manière suivante, pour refuser
              l’installation des cookies :<br />
              Sous Internet Explorer : onglet outil / options internet. Cliquez
              sur Confidentialité et choisissez Bloquer tous les cookies.
              Validez sur Ok.
              <br />
              Sous Netscape : onglet édition / préférences. Cliquez sur Avancées
              et choisissez Désactiver les cookies. Validez sur Ok.
            </p>
            <h5>7. Droit applicable et attribution de juridiction :</h5>

            <p>
              Tout litige en relation avec l’utilisation du site
              https://kimekoif.com/ est soumis au droit français. L’utilisateur
              ainsi que https://kimekoif.com/ acceptent de se soumettre à la
              compétence exclusive des tribunaux Français en cas de litige.
            </p>

            <h5>
              8. Protection des biens et des personnes - gestion des données
              personnelles :
            </h5>
            <p>
              Utilisateur : Internaute se connectant, utilisant le site susnommé
              : https://kimekoif.com/ En France, les données personnelles sont
              notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi
              n° 2004-801 du 6 août 2004, l&#39;article L. 226-13 du Code pénal
              et la Directive Européenne du 24 octobre 1995.
              <br />
              Conformément aux dispositions des articles 38 et suivants de la
              loi 78-17 du 6 janvier 1978 relative à l’informatique, aux
              fichiers et aux libertés, tout utilisateur dispose d’un droit
              d’accès, de rectification, de suppression et d’opposition aux
              données personnelles le concernant. Pour l’exercer, adressez votre
              demande à https://kimekoif.com/ par courriel : courriel du
              webmaster ou en effectuant sa demande écrite et signée,
              accompagnée d’une copie du titre d’identité avec signature du
              titulaire de la pièce, en précisant l’adresse à laquelle la
              réponse doit être envoyée.
              <br />
              Aucune information personnelle de l&#39;utilisateur du site
              https://kimekoif.com/ n&#39;est publiée à l&#39;insu de
              l&#39;utilisateur, échangée, transférée, cédée ou vendue sur un
              support quelconque à des tiers. Seule l&#39;hypothèse du rachat du
              site https://kimekoif.com/ au propriétaire du site et de ses
              droits permettrait la transmission des dites informations à
              l&#39;éventuel acquéreur qui serait à son tour tenu de la même
              obligation de conservation et de modification des données vis à
              vis de l&#39;utilisateur du site https://macoiffeuseafro.com/.
              <br />
              Le site https://kimekoif.com/ est déclaré à la CNIL sous le numéro
              1641666 et 1641667. Les bases de données sont protégées par les
              dispositions de la loi du 1er juillet 1998 transposant la
              directive 96/9 du 11 mars 1996 relative à la protection juridique
              des bases de données.
            </p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MentionLegale;
